import React, { useMemo } from 'react';
import { CardWrapper } from './CardWrapper';
import { CardBodyBase, CardFormatType, CardBodyType } from '@models/card';
import Grid, { GridProps } from '@mui/material/Grid';
import { CMSLinkProps, CMSLink } from '@components/CMSLink';
import { Picture } from '../Picture';
import { contentStylePackage as classes, bodyTypeStylePackage as bodyStyles } from './CardStyle';
import { ClassNames } from '@emotion/react';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { getLinkTargetValue } from '../SectionComponents/helpers/SectionComponentHelper';
import { getFormattedLocalDateTime } from '@helpers/dates';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { languageRouteTypes } from '@helpers/languageRoutes';
import { ensureInternalURLTrailingSlash } from '@helpers/string';
import { PublicDateTime } from '@components/PublicDateTime';

interface BaseProps {
    card: CardBodyBase;
    href?: string;
    wrapperClass?: string;
    reverse?: boolean;
    noHover?: boolean;
    customSize?: number;
    isHidden?: boolean;
    shouldIncreaseHeadingLevel?: boolean;
}

export type CardProps = BaseProps & Omit<GridProps, keyof BaseProps> & Omit<CMSLinkProps, keyof BaseProps>;

export function Card({
    card,
    href,
    reverse,
    onClick,
    onKeyDown,
    customSize,
    noHover,
    wrapperClass,
    isHidden,
    shouldIncreaseHeadingLevel,
    ...other
}: CardProps): JSX.Element | null {
    const { shadowType, cardPadding, rounded, format, cardBodyType, content } = card;
    const isWrapperLink = cardBodyType === CardBodyType.None;

    const availablePictureLayout =
        format === CardFormatType.Full ||
        format === CardFormatType.Split ||
        format === CardFormatType.GalleryTile ||
        format === CardFormatType.NoContentGalleryCard ||
        format === CardFormatType.Long ||
        format === CardFormatType.LongSmall;

    const availableImageLayout =
        format === CardFormatType.Default ||
        format === CardFormatType.Restrictive ||
        format === CardFormatType.RestrictiveFourColumn ||
        format === CardFormatType.Long ||
        format === CardFormatType.LongSmall ||
        format === CardFormatType.SingleBreak ||
        format === CardFormatType.FourColumn ||
        format === CardFormatType.Full ||
        format === CardFormatType.Split ||
        format === CardFormatType.GalleryTile ||
        format === CardFormatType.NoContentGalleryCard;

    const contentImage = content.image;

    const [selectedLanguage] = useAtom(selectedLanguageAtom);

    const containerStyle = [
        bodyStyles.contentContainer,
        bodyStyles[`padding-${cardPadding}`] || '',
        format === CardFormatType.Full || format === CardFormatType.Split ? bodyStyles[`innerLayout-${format}`] : '',
        card.cardBodyType === CardBodyType.Centered ? bodyStyles.centered : '',
    ];

    const bodyContent = useMemo(() => {
        switch (card.cardBodyType) {
            case CardBodyType.Layered:
                return (
                    <>
                        {card.content.subtitle ? (
                            <Heading variant='h4' component='p' css={bodyStyles['layered-subtitle']} color='tertiary'>
                                {card.content.subtitle}
                            </Heading>
                        ) : null}
                        {card.content.title ? (
                            <Heading
                                variant='h3'
                                weight='regular'
                                component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                                css={bodyStyles['layered-title']}
                            >
                                {card.content.title}
                            </Heading>
                        ) : null}
                        {card.content.body ? (
                            <RichTextContent
                                content={card.content.body}
                                hideLinks={isHidden}
                                css={bodyStyles['layered-body']}
                            />
                        ) : null}
                        {card.content.linkText ? (
                            <CMSLink
                                href={card.content.linkPath}
                                data-testid='cardBody-layered-link'
                                target={getLinkTargetValue(card.content.linkTarget)}
                                variant='text'
                                type='button'
                                css={bodyStyles['layered-link']}
                                aria-label={card.content.ariaLabel}
                                tabIndex={isHidden ? -1 : undefined}
                            >
                                {card.content.linkText}
                            </CMSLink>
                        ) : null}
                    </>
                );
            case CardBodyType.CTA:
                return (
                    <>
                        <div>
                            {Array.isArray(card.content.tags)
                                ? card.content.tags.map(({ name, slug }, index) =>
                                    name ? (
                                        <React.Fragment key={name}>
                                            <Text
                                                variant='body2'
                                                color='secondary'
                                                data-testid={`cardBody-CTA-tag-${name}`}
                                                {...(slug
                                                    ? {
                                                        component: CMSLink,
                                                        href: ensureInternalURLTrailingSlash(
                                                                `${getLanguageRouteAndPrefixByCodeAndRouteKey(
                                                                    selectedLanguage,
                                                                    languageRouteTypes.TAG
                                                                )}${slug}`
                                                        ),
                                                        tabIndex: isHidden ? -1 : undefined,
                                                    }
                                                    : null)}
                                                css={slug ? bodyStyles['cta-tags'] : bodyStyles['cta-tags-no-link']}
                                            >
                                                {`${name}${
                                                      index !== (card.content.tags && card.content.tags.length - 1)
                                                          ? ','
                                                          : ''
                                                  }`}
                                            </Text>
                                              &nbsp;
                                        </React.Fragment>
                                    ) : null
                                )
                                : null}
                        </div>
                        <Heading
                            variant={card.content.titleVariant || 'h4'}
                            component='h2'
                            css={bodyStyles['cta-title']}
                        >
                            {card.content.title}
                        </Heading>
                        {card.content.body ? <RichTextContent content={card.content.body} /> : null}
                        {card.content.linkText && href ? (
                            <Grid container alignItems='flex-end' css={{ flex: 1 }}>
                                <CMSLink
                                    href={href}
                                    target={getLinkTargetValue(card.content.linkTarget)}
                                    variant='text'
                                    aria-label={card.content.ariaLabel}
                                    type='button'
                                    disableRipple
                                    css={[
                                        !card.content.tags?.length ? bodyStyles.fullSpanLink : '',
                                        bodyStyles[`${card.cardBodyType}-link`],
                                    ]}
                                    tabIndex={isHidden ? -1 : undefined}
                                >
                                    {card.content.linkText}
                                </CMSLink>
                            </Grid>
                        ) : null}
                        {!(card.content.linkText && href) && Array.isArray(card.content.buttons) ? (
                            <Grid
                                container
                                alignItems='flex-end'
                                css={bodyStyles[`${card.cardBodyType}-button-container`]}
                            >
                                {card.content.buttons.map(
                                    ({ buttonText, buttonLink, target, buttonIcon, ariaLabel }, index) => (
                                        <CMSLink
                                            key={index}
                                            variant='text'
                                            type='button'
                                            aria-label={ariaLabel}
                                            href={buttonLink}
                                            target={target || '_self'}
                                            css={bodyStyles['cta-button']}
                                            tabIndex={isHidden ? -1 : undefined}
                                        >
                                            {buttonIcon ? buttonIcon : null}
                                            {buttonText}
                                        </CMSLink>
                                    )
                                )}
                            </Grid>
                        ) : null}
                    </>
                );
            case CardBodyType.Default:
            case CardBodyType.Centered:
                return (
                    <>
                        {card.content.title ? (
                            <Text
                                variant={card.content.titleVariant || 'h4'}
                                component='h2'
                                data-testid='cardBody-default-title'
                                color='tertiary'
                            >
                                {href ? (
                                    <a
                                        href={href}
                                        target={getLinkTargetValue(card.content.linkTarget)}
                                        css={[bodyStyles.fullSpanLink, { all: 'unset' }]}
                                        tabIndex={isHidden ? -1 : undefined}
                                    >
                                        {card.content.title}
                                    </a>
                                ) : (
                                    card.content.title
                                )}
                            </Text>
                        ) : null}
                        {card.content.body ? <RichTextContent content={card.content.body} /> : null}
                        <Grid container>
                            {card.content.publishedDateUtc ? (
                                <PublicDateTime>
                                    <Text variant='body2' color='tertiary' data-testid='cardBody-default-date'>
                                        {getFormattedLocalDateTime(
                                            card.content.publishedDateUtc,
                                            'LL',
                                            selectedLanguage
                                        )}
                                    </Text>
                                </PublicDateTime>
                            ) : null}
                            {card.content.readMinutes ? (
                                <Text variant='body2' color='tertiary'>
                                    &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                                    <span css={bodyStyles.bold}>{card.content.readMinutes}</span>
                                </Text>
                            ) : null}
                        </Grid>
                    </>
                );
        }
    }, [card.cardBodyType, card.content, href, selectedLanguage, shouldIncreaseHeadingLevel, isHidden]);

    const ImageIcon = contentImage?.overlayIcon;

    return (
        <CardWrapper
            hasBorder={card.border}
            shadowType={shadowType}
            rounded={rounded}
            lazyLoad={content?.image?.lazyLoad}
            cardFormat={format}
            noHover={noHover}
            reverse={reverse}
            href={href}
            target={card.content.linkTarget}
            onClick={onClick}
            onKeyDown={onKeyDown}
            customSize={customSize}
            isLink={isWrapperLink}
            ariaLabel={'ariaLabel' in card.content ? card.content.ariaLabel : undefined}
            className={wrapperClass || ''}
            isHidden={isHidden}
            {...other}
        >
            <>
                {contentImage &&
                Object.keys(contentImage).length &&
                contentImage.imagePath &&
                contentImage.imagePath !== 'undefined' &&
                contentImage.imagePath !== '' ? (
                        <ClassNames>
                            {({ css }) => (
                                <Picture
                                    css={[
                                        classes.image,
                                        availableImageLayout ? classes[`imageLayout-${format}`] : undefined,
                                        reverse ? classes['image-reverse'] : '',
                                        !rounded ? classes['image-notRounded'] : '',
                                    ]}
                                    data-testid='cardContent-image'
                                    pictureProps={{
                                        className: css([
                                            classes.picture,
                                            availablePictureLayout ? classes[`pictureLayout-${format}`] : undefined,
                                            reverse ? classes['picture-reverse'] : '',
                                        ]),
                                    }}
                                    alt={contentImage.imageAlt || ''}
                                    lazyLoad={contentImage.lazyLoad}
                                    imagePath={contentImage.imagePath}
                                    mobilePath={contentImage.mobilePath ?? ''}
                                    mobileAlt={contentImage.mobileAlt}
                                />
                            )}
                        </ClassNames>
                    ) : null}
                {ImageIcon ? <ImageIcon css={classes.overlayIcon} /> : null}
                {card.cardBodyType !== CardBodyType.None ? (
                    <Grid
                        container
                        direction={cardBodyType === CardBodyType.CTA ? 'column' : 'row'}
                        data-testid='cardContentContainer'
                        css={[containerStyle, cardBodyType === CardBodyType.CTA ? { flex: 1 } : '']}
                    >
                        {bodyContent}
                    </Grid>
                ) : null}
            </>
        </CardWrapper>
    );
}
